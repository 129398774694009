/* # Font Import
---------------------------------------------------------------------------------------------------- */
@import url(https://fonts.googleapis.com/css?family=Oswald:400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400italic);
html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing:    border-box;
    box-sizing:         border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
html {
    font-size: 62.5%;
    max-width: 100%;
    overflow-x: hidden;
}
body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
    color: #343333;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

p { margin-top: 0; }

.content {
    margin: 80px auto 30px auto;
    max-width: 1200px;
    padding: 0 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    display: inline-block;
}
h1 {
    font-family: 'Oswald', sans-serif;
    font-size: 63px;
    font-weight: 400;
    color: #fff;
}
h1 span {
    color: #F62459;
    font-weight: 700;
}
h2 {
    color: #fff;
    font-size: 25px;
    font-weight: 500;
}
h2 span {
    color: #F62459;
}

h2.section-title {
    color: #343333;
    font-size:35px;
}

h3.section-title {
    color: #343333;
    font-size:20px;
    margin: 10px 0;
}

h3 {
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 16px;
    opacity: 0.9;
    font-style: italic;
    font-weight: 300;
    display: block;
}
#root {
    width: 100vw;
    height: 100vh;
}
.content-image {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    border: 2px solid #F62459;
}
a {
    color: #F62459;
    text-decoration: none;
}

a:hover {
    color: #F62459;
    text-decoration: underline;
}
